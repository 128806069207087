import useLocale from '@app/hooks/useLocale';
import React from 'react';

const AppDownloadPage: React.FC = () => {
  const SCOPE_OPTIONS = { scope: 'components.AppDownloadPage' };
  const { t } = useLocale();

  return (
    <main className="flex flex-col mx-auto w-full text-center justify-center items-center h-screen">
      <img loading="lazy" src="/images/mobile-background.png" alt="" className="fixed inset-0 w-full" />
      <div className="flex relative flex-col px-4 py-8 w-full justify-center items-center *y">
        <header>
          <img
            loading="lazy"
            src="/images/bubble-logo.png"
            alt="App logo"
            className="object-contain self-center max-w-full aspect-[1.22] w-[180px] md:w-[243px]"
          />
        </header>
        <section className="mt-8 md:mt-20">
          <h1 className="text-xl md:text-2xl font-bold text-neutral-800">{t('title', SCOPE_OPTIONS)}</h1>
          <p className="self-center mt-2 text-base md:text-lg text-neutral-500">{t('description', SCOPE_OPTIONS)}</p>
        </section>
        <div className="mt-6 md:mt-9 -mb-5 flex flex-col items-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.branchsight.branchsight_branch"
            className="bg-[#3970FE] mx-auto mt-4 w-full max-w-[269px] rounded-4 flex gap-4 items-center py-4 px-8"
          >
            <img src="/images/playstore.png" width={40} alt="Play Store" />
            <div className="flex flex-col text-white text-left">
              <span className="font-">{t('download', SCOPE_OPTIONS)}</span>
              <span className="font-bold text-2xl">Google Play</span>
            </div>
          </a>
          <a
            href="https://apps.apple.com/tr/app/digital-dealer-platform/id6449195119"
            className="bg-[#3970FE] mx-auto mt-4 w-full max-w-[269px] rounded-4 flex gap-4 items-center py-4 px-8"
          >
            <img src="/images/appstore.png" width={40} alt="App Store" />
            <div className="flex flex-col text-white text-left">
              <span className="font-">{t('download', SCOPE_OPTIONS)}</span>
              <span className="font-bold text-2xl">App Store</span>
            </div>
          </a>
        </div>
      </div>
    </main>
  );
};

export default AppDownloadPage;
