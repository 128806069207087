import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-input-range/lib/css/index.css';
import '@app/styles/globals.scss';

import App from 'next/app';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
import { Waiter } from 'react-wait';

import { COOKIE_KEYS } from '@app/constants';
import { AuthProvider } from '@app/hooks/useAuth';
import { AgreementModalProvider } from '@app/hooks/useAgreementModal';
import { LocaleProvider } from '@app/hooks/useLocale';
import { useWindowSize } from '@app/hooks';

import { CheckFacebookAccount, Facebook, ThirdPartyAnalytics, AppDownloadPage } from '@app/components';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

function MyApp({ Component, pageProps, locale }: any) {
  const { width } = useWindowSize();

  if (width && width < 1024) {
    return (
      <LocaleProvider initialLocale={locale}>
        <AppDownloadPage />;
      </LocaleProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Waiter>
        <AuthProvider>
          <AgreementModalProvider>
            <LocaleProvider initialLocale={locale}>
              <Facebook.LoginModal />
              <ThirdPartyAnalytics />
              <ToastContainer
                autoClose={false}
                position="bottom-center"
                toastClassName="shadow-none bg-transparent p-0 min-h-full"
                bodyClassName="p-0 !m-0"
                className="max-w-100 w-full p-0"
              />
              <CheckFacebookAccount>
                <Component {...pageProps} />
              </CheckFacebookAccount>
            </LocaleProvider>
          </AgreementModalProvider>
        </AuthProvider>
      </Waiter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

MyApp.getInitialProps = async (appContext: any) => {
  const appProps = await App.getInitialProps(appContext);
  const req = appContext.ctx.req;
  const locale = req?.headers?.['accept-language']?.split(',')[0]?.split('-')[0];
  if (req?.cookies) {
    if (Object.prototype.hasOwnProperty.call(req?.cookies, COOKIE_KEYS.LOCALE)) {
      const locale = req.cookies[COOKIE_KEYS.LOCALE];
      return { ...appProps, locale };
    }
  }

  return { ...appProps, locale };
};

export default MyApp;
